<template>
  <div>
    <Header />
    <!--Beneficios-->
    <div class="DivFull_LeftCenter DivPortaBtnVoltaBeneficios">
      <div class="DivBtnVoltaBeneficios" id="DivBtnVoltaBeneficios">
        <a
          @click="Show_Info()"
          href="javascript:void(0)"
          class="BtnVoltaBeneficios"
          id="BtnVoltaBeneficios"
        >
          <img src="./svg/ShowBeneficios.svg" class="" alt="CLOSE" />
        </a>
      </div>
    </div>

    <div class="DivFull_LeftCenter Beneficios" id="Beneficios">
      <div class="container-fluid">
        <div class="col-md-6">
          <div class="rowBlock">
            <h1 class="TituloCor3">
              Lentes<br />
              Ocupacionais
            </h1>

            <h1 class="TituloCor3" id="TituloVisaoSimples">Meia Distância</h1>

            <a
              @click="Hide_Info()"
              href="javascript:void(0)"
              class="BtnFechaBeneficios"
              id="BtnFechaBeneficios"
            >
              <img
                src="./svg/CloseBeneficios.svg"
                class="IconeClose"
                alt="CLOSE"
              />
            </a>
          </div>

          <div class="DivEscolhas">
            <div class="row Btns_ShowSemAntirreflexo DeixaInativo">
              <a
                href="javascript:void(0)"
                class="Txt_EmLinha Txt_EmLinha_Tamanho2 Show_SemAntirreflexo"
                id="ObsMeia"
                @click="showMedium()"
                >AO LADO, VISÃO DE</a
              >

              <a
                href="javascript:void(0)"
                class="BtnEmLinha1 BackgroundColor2 TextoBtnCor2 BtnEmLinhaInativo"
                id="Mostra_MeiaDistancia"
                @click="showMedium()"
                >MEIA DISTÂNCIA</a
              >
            </div>

            <div class="row Btns_ShowAntirreflexo">
              <a
                href="javascript:void(0)"
                class="Txt_EmLinha Txt_EmLinha_Tamanho2"
                id="ObsPerto"
                @click="showClose()"
                >MUDE PARA VISÃO DE</a
              >

              <a
                href="javascript:void(0)"
                class="BtnEmLinha1 BackgroundColor2 TextoBtnCor2"
                id="Mostra_Perto"
                @click="showClose()"
                >PERTO</a
              >
            </div>

            <div class="row top10">
              <a
                @click="Open_Modal()"
                href="javascript:void(0)"
                class="ShowBeneficios"
                id="AbreModalBeneficios"
              >
                <img src="./svg/OpenCollapse.svg" class="" alt="Saiba mais" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Beneficios-->

    <!--Modal Beneficios-->
    <div class="ModalBeneficios" id="ModalBeneficios">
      <div class="HeaderModal">
        <h2 class="TituloCor1">Lentes Ocupacionais</h2>
      </div>

      <a
        @click="Close_Modal()"
        href="javascript:void(0)"
        class="BtnCloseModal"
        id="FechaModalBeneficios"
      >
        <img src="./svg/CloseModal.svg" alt="Fechar" />
      </a>

      <div class="container ContainerModal">
        <div class="col-12 NoPadding">
          <div class="DivModalBeneficios" id="DivModalBeneficios">
 <carousel
              :autoplay="false"
              :loop="false"
              :nav="true"
              :dots= "true"
              :responsive="{0:{items:1,nav:false},450:{items:1,nav:true},1000:{items:1,nav:true}}"       
            >     

              <div class="item">
                <div class="row">
                  <img
                    src="./images/Ocupacionais/Banner_Ocupacionais_1.jpg"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    As lentes ocupacionais são desenvolvidas para pessoas que
                    necessitam de correção visual e trabalham em ambientes
                    fechados como escritório, que usam com frequência os campos
                    de visão de leitura ou meia distância.<br />
                    São lentes que maximizam os campos de visão mais utilizados
                    nestes ambientes, deixando a visão mais nítida e relaxada
                    como também auxilia na melhor postura pois elimina
                    movimentos tensos do pescoço e da coluna para encontrar uma
                    posição mais confortável de visão.<br />
                    Não substituem os óculos de uso cotidiano, são aconselháveis
                    como óculos de trabalho.
                  </p>
                </div>
              </div>

              <div class="item ">
                <div class="row">
                  <div class="col-4">
                    <img
                      src="./images/Ocupacionais/Ocupacionais_Postura_VisaoSimples.jpg"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">LENTES DE VISÃO SIMPLES</h5>
                      <p class="MediumTxt White Opacidade5 top5">
                        A tendência é que os usuários curvem-se na direção dos
                        objetos para que possam foca-los, resultando em tensão
                        exagerada nos músculos do pescoço e das costas como
                        também provoca esforço desnecessário da coluna, levando
                        a sensa
                      </p>
                    </div>
                  </div>

                  <div class="col-4">
                    <img
                      src="./images/Ocupacionais/Ocupacionais_Postura_Progressivas.jpg"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">LENTES PROGRESSIVAS</h5>
                      <p class="MediumTxt White Opacidade5 top5">
                        Normalmente os usuários costumam levantar a cabeça para
                        buscar nas lentes o campo de visão de perto ou
                        intermediário, resultando em tensão no pescoço e nos
                        ombros, aumentando também o esforço ocular que pode
                        levar a sensação de vista cansada e dor de cabeça.
                      </p>
                    </div>
                  </div>

                  <div class="col-4">
                    <img
                      src="./images/Ocupacionais/Ocupacionais_Postura_Ocupacionais.jpg"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">LENTES OCUPACIONAIS</h5>
                      <p class="MediumTxt White Opacidade5 top5">
                        As lentes ocupacionais proporcionam aos usuários uma
                        visão nítida, confortável e principalmente relaxada nas
                        distâncias mais usadas no ambiente de trabalho,
                        auxiliando na postura correta com a cabeça e pescoço
                        numa postura natural e relaxada, o dia todo.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
    <!--Modal Beneficios-->
    <div class="DivFull_CenterCenter OverlayBlack" id="OverlayBlack"></div>
    <div class="Ocupacionais_Perto" id="Background_OcupacionaisPerto"></div>
    <div class="Ocupacionais_Meia" id="Background_OcupacionaisMeia"></div>
  </div>
</template>

<script>
import Header from '@/views/menu/components/header.vue'
import { showClose, showMedium } from '@/views/menu/js/new/Occupational'
import carousel from 'vue-owl-carousel'

import {
  Hide_Info,
  Show_Info,
  Open_Modal,
  Close_Modal,
} from '@/views/menu/js/new/Scripts'

export default {
  components: {
    Header,
    carousel
  },
  methods: {
    showClose,
    showMedium,
    Hide_Info,
    Show_Info,
    Open_Modal,
    Close_Modal,
  },
}
</script>

<style scoped>
@import './css/Style.css';
@import './css/MyStyle.css';
@import './css/MyLogin.css';
@import './css/MyColors.css';
@import './css/MyMenu.css';
@import './css/MyMenuInterno.css';
@import './css/plugins/animate.css';
@import './css/plugins/hamburger.css';
/* @import './css/plugins/owl.carousel.min.css';  */
@import './css/plugins/owl.theme.default.min.css';
@import './css/Ocupacionais_Style.css';
</style>
