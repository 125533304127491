export function showClose() {
  document
    .getElementById('Background_OcupacionaisMeia')
    .classList.add('Perto_Ocupacionais')
  document
    .getElementById('Background_OcupacionaisPerto')
    .classList.add('Show_OcupacionaisPerto')
  document.getElementById('Mostra_Perto').classList.add('BtnEmLinhaInativo')

  //document.getElementById('Btns_ShowOcupacionais_Perto').classList.add('DeixaInativo')

  document
    .getElementById('Mostra_MeiaDistancia')
    .classList.remove('BtnEmLinhaInativo')

  document
    .getElementById('Btns_ShowOcupacionais_Meia')
    .classList.remove('DeixaInativo')
  document.getElementById('TituloOcupacionais').classList.remove('Hide')

  /*
$("#Mostra_Perto,#ObsPerto").on('click', function() {

    $('#ObsMeia').text('MUDE PARA VISÃO DE');
    $('#ObsPerto').text('NA IMAGEM, VISÃO DE');
    $('#TituloOcupacionais').removeClass('Hide');
    $('#TituloOcupacionais').text('PARA PERTO');
    $('#TituloVisaoSimples').text('Perto');
*/
}

export function showMedium() {
  document
    .getElementById('Mostra_MeiaDistancia')
    .classList.add('BtnEmLinhaInativo')
  //document.getElementById('Btns_ShowOcupacionais_Meia').classList.add('DeixaInativo')

  document
    .getElementById('Background_OcupacionaisMeia')
    .classList.remove('Perto_Ocupacionais')
  document
    .getElementById('Background_OcupacionaisPerto')
    .classList.remove('Show_OcupacionaisPerto')
  document.getElementById('Mostra_Perto').classList.remove('BtnEmLinhaInativo')
  document
    .getElementById('Btns_ShowOcupacionais_Perto')
    .classList.remove('DeixaInativo')
  document.getElementById('TituloOcupacionais').classList.remove('Hide')

  /*
    $("#Mostra_MeiaDistancia,#ObsMeia").on('click', function() {
    
        $('#ObsPerto').text('MUDE PARA VISÃO DE');
        $('#ObsMeia').text('NA IMAGEM, VISÃO DE');
        $('#TituloOcupacionais').text('PARA MEIA DISTÂNCIA');
        $('#TituloVisaoSimples').text('Meia Distância');
*/
}
